import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export function Footer() {
  const intl = useIntl();
  const { locale } = intl;

  return (
    <section
      className="w-full flex flex-wrap bg-gray-900 text-gray-100 py-10"
      id="footer"
    >
      <div className="mx-auto">
        <span>@ 2020 Christian Bühlmeyer</span>&nbsp; | &nbsp;
        <a href={`/${locale}/imprint`} rel="nofollow">
          <FormattedMessage
            id={"footer.general.imprint"}
            defaultMessage="Imprint"
            description="Link to the imprint"
          />
        </a>
      </div>
    </section>
  );
}
