import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Route } from 'react-router-dom';

import './assets/main.css';
import './assets/animation.css';
import reportWebVitals from './reportWebVitals';
import history from './utils/history';
import { Landing } from './views/Landing/Landing';
import { getLanguage } from 'utils/translation';
import { Header } from 'components/header';
import { Footer } from 'views/Landing/Footer';
import { Imprint } from 'views/Imprint';
import { LanguageWrapper } from 'components/LanguageWrapper';

const initialLanguage = getLanguage();

interface HeaderFooterLayoutProps {
  children: any,
}

const HeaderFooterLayout = (props: HeaderFooterLayoutProps) => {
  const { children } = props;

  return (
    <>
      <Header/>
      { children }
      <Footer />
    </>
  )
}

const App = () => {

  return (
    <Router history={history}>
      <LanguageWrapper>
        <HeaderFooterLayout>
          <Route exact path='/:lang/imprint' component={ Imprint } />
          <Route exact path='/:lang/dataprivacy' component={ Landing } />
          <Route exact path='/:lang/' component={ Landing } />
        </HeaderFooterLayout>
      </LanguageWrapper>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
