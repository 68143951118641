import React from 'react';
import { Hero } from './Hero';
import { Technologies } from './Technologies';
import { WhatWeDo } from './WhatWeDo';
import { WorkExperience } from './WorkExperience';
import { ProjectPortfolio } from './ProjectPortfolio';
import { ContactMe } from './ContactMe';
import { AboutMe } from './AboutMe';

export function Landing() {
  return (
    <div className="w-full">
      <Hero/>
      <AboutMe />
      <WhatWeDo />
      <WorkExperience />
      <Technologies />
      <ProjectPortfolio />
      <ContactMe />
    </div>
  );
}
