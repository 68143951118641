import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { translatedMessages } from 'assets/languages';
import { Languages } from 'models/enums/Languages';

interface LanguageWrapperProps {
  children: any,
}

export function LanguageWrapper({ children }: LanguageWrapperProps) {

  const getLangFromUrl = (urlLang: string) => {
    switch (urlLang) {
      case 'en': return Languages.English;
      case 'de': return Languages.German;
      default: return Languages.English;
    }
  }

  return (
    <Switch>
      <Route path="/:lang([a-z]{2})">
        {({ match }) => {
          const selectedLanguage = getLangFromUrl(match?.params.lang);

          return (
            <IntlProvider locale={selectedLanguage} messages={translatedMessages[selectedLanguage]}>
              <Switch>
                { children }
              </Switch>
            </IntlProvider>
          )
        }}
      </Route>
      <Redirect from="/" to="/en" />
    </Switch>
  )
}
