import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tada from 'react-reveal/Tada';

import EnvelopeImg from '../../assets/images/envelope.png';

export function ContactMe() {
  return (
    <section
      className="w-full bg-gradient-to-r from-teal-400 to-blue-500"
      id="contactMe"
    >
      <div className="container mx-auto p-6 pt-10">
        <h2 className="text-3xl mb-2 font-bold text-gray-100 text-center">
          <FormattedMessage
            id={"header.navigation.contactMe"}
            defaultMessage="Contact Me"
            description="Link to the contact"
          />
        </h2>
        <div className="flex flex-row flex-col flex-wrap">
          <div className="w-1/4 mx-auto">
            <a href="mailto:contact@christian-buehlmeyer.com">
              <Tada>
                <img src={EnvelopeImg} className="zoomHover" />
              </Tada>
            </a>
          </div>
          <span className="text-center text-gray-100">
            <a href="mailto:contact@christian-buehlmeyer.com">
              contact@christian-buehlmeyer.com
            </a>
          </span>
        </div>
      </div>
    </section>
  );
}
