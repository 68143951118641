import { Languages } from 'models/enums/Languages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import history from 'utils/history';


export function Header() {

  return (
    <header className="w-full absolute z-40 text-gray-100 flex items-center justify-between flex-wrap px-6">
      <div className="flex items-center flex-no-shrink mr-6">
        <span className="font-semibold text-xl tracking-tight">
          <FormattedMessage
            id={"header.website.title"}
            defaultMessage="Christian Buehlmeyer"
            description="Title of the website"
          />
        </span>
      </div>
      <nav className="hidden md:block flex-grow text-sm lg:flex lg:items-center lg:w-auto lg:flex-grow">
          <a href='/#whatIDo' className="inline-block hover:text-teal-500 mr-4">
            <FormattedMessage
              id={"header.navigation.offering"}
              defaultMessage="What we offer"
              description="Link to the offering"
            />
          </a>
          <a href='/#technologies' className="inline-block hover:text-teal-500 mr-4">
            <FormattedMessage
              id={"header.navigation.usedTechnologies"}
              defaultMessage="Used Technologies"
              description="Link to used technologies"
            />
          </a>
          <a href='/#workExperience' className="inline-block hover:text-teal-500 mr-4">
            <FormattedMessage
              id={"header.navigation.workExperience"}
              defaultMessage="Work Experience"
              description="Link to my work experience"
            />
          </a>
      </nav>
      <div className="text-sm">
        <div
          className="inline-block cursor-pointer hover:text-teal-500"
          onClick={() => history.push(`/${Languages.German}`)}
        >
          <FormattedMessage
            id={"header.language.german"}
            defaultMessage="German"
            description="German language selection"
          />
        </div>
        <span className="mx-3">|</span>
        <div
          className="inline-block cursor-pointer hover:text-teal-500"
          onClick={() => history.push(`/${Languages.English}`)}
        >
          <FormattedMessage
            id={"header.language.english"}
            defaultMessage="English"
            description="English language selection"
          />
        </div>
      </div>
    </header>
  );
}
