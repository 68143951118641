import React from 'react';

import RouteToSuccessImg from '../../assets/images/route_to_success.jpg';
import ClearVisionImg from '../../assets/images/clear_vision.jpg';
import LeverageCloudImg from '../../assets/images/leverage_cloud.jpg';

import { FormattedMessage } from 'react-intl';

interface ImageText {
    image: string;
    textLine1: string;
    textLine2: string;
    subText: string;
}

const carousel: Array<ImageText> = [
    {
        image: RouteToSuccessImg,
        textLine1: 'hero.message1.textLine1',
        textLine2: 'hero.message1.textLine2',
        subText: 'hero.message1.subText',
    },
    {
        image: LeverageCloudImg,
        textLine1: 'hero.message2.textLine1',
        textLine2: 'hero.message2.textLine2',
        subText: 'hero.message2.subText',
    },
    {
        image: ClearVisionImg,
        textLine1: 'hero.message3.textLine1',
        textLine2: 'hero.message3.textLine2',
        subText: 'hero.message3.subText',
    }
]

export function Hero() {
    const [ carouselIndex, setCarouselIndex ] = React.useState(0);

    React.useEffect(() => {
        setInterval(() => {
            setCarouselIndex((currIndex) => {
                return (currIndex + 1) % carousel.length;
            });
        }, 5000);
    }, []);

    const createStackClassForImages = (index: number) => {
        let stackClass = 'opacity-100';
        if (index === carouselIndex) stackClass = 'fade-in';
        if (index > carouselIndex) stackClass = 'opacity-0';
        return stackClass;
    }

    const createStackClassForText = (index: number) => {
        let stackClass = 'opacity-0';
        if (index === carouselIndex) stackClass = 'fade-in';
        if (index > carouselIndex) stackClass = 'opacity-0';
        return stackClass;
    }

    const renderCarouselImage = (image: string, index: number) => {
        return (
            <img
                key={`carousel-${index}`}
                src={image}
                className={`absolute inset-0 object-cover min-w-full min-h-full ${createStackClassForImages(index)}`}
            />
        )
    }

    const renderCarouselText = (textLine1: string, textLine2: string, subText: string, index: number) => {
        return (
            <div key={`carouselText-${index}`} className={`absolute inset-0 flex flex-col place-content-center items-center text-gray-100 ${createStackClassForText(index)}`}>
                <h2 className="text-2xl sm:text-5xl xl:text-6xl font-bold text-center leading-tight mb-5">
                    <FormattedMessage
                        id={textLine1}
                        defaultMessage="WE DEVELOP"
                        description="Our Offering Line 1"
                    />
                    <br/>
                    <FormattedMessage
                        id={textLine2}
                        defaultMessage="FOR YOU"
                        description="Our Offering Line 2"
                    />
                </h2>
                <span className="text-center">
                    <FormattedMessage
                        id={subText}
                        defaultMessage="We build complex web solutions with you -for your customers"
                        description="Description Line"
                    />
                </span>
            </div>
        );
    }

    return (
        <section className="h-screen overflow-hidden relative">
            <img
                key='carousel-base'
                src={carousel[carousel.length-1].image}
                className={`absolute inset-0 object-cover min-w-full min-h-full`}
            />
            {
                carousel.map((carousel, index) => renderCarouselImage(carousel.image, index))
            }
            <img src={RouteToSuccessImg} className="object-cover min-w-full min-h-full"/>
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            {
                carousel.map((carousel, index) => renderCarouselText(carousel.textLine1, carousel.textLine2, carousel.subText, index))
            }
            <div className="absolute bottom-0 left-0 right-0 h-20 hidden md:flex space-around text-gray-500">
            {
                carousel.map((carousel, index) => (
                    <div key={`heroInfoText-${index}`} className={`flex-grow flex place-content-center items-center text-center relative`}>
                        <div className={`absolute inset-0 ${carouselIndex !== index && 'bg-black bg-opacity-50'}`}></div>
                        <FormattedMessage
                            id={carousel.textLine1}
                            defaultMessage="WE DEVELOP"
                            description="Our Offering Line 1"
                        />
                        <br/>
                        <FormattedMessage
                            id={carousel.textLine2}
                            defaultMessage="FOR YOU"
                            description="Our Offering Line 2"
                        />
                    </div>
                ))
            }   
            </div>
        </section>
    );
}
