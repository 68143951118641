import React from 'react';
import { FormattedMessage } from 'react-intl';

import MockupFoxImage from '../../assets/images/mockupfox.jpg';
import MockupFoxLogo from '../../assets/images/mockupfox_logo.svg';
import Aqua4FishImage from '../../assets/images/aqua4fish.jpg';
import Aqua4FishLogo from '../../assets/images/aqua4fish_logo.png';
import SeifenSiedePunktImage from '../../assets/images/seifensiedepunkt.jpg';
import SeifenSiedePunktLogo from '../../assets/images/seifensiedepunkt_logo.png';
import { Pill } from 'components/Pill';

interface ProjectCardProps {
  title: string;
  logo: string;
  url: string;
  description: string;
  img: string;
  languages: string;
  hashtags: Array<string>;
}

const MyProjects: Array<ProjectCardProps> = [
  {
    title: ' ',
    logo: MockupFoxLogo,
    url: 'https://www.mockupfox.com',
    img: MockupFoxImage,
    description: 'projectPortfolio.mockupfox.description',
    languages: 'projectPortfolio.language.germanEnglish',
    hashtags: ['react', 'nodejs', 'threejs', 'stripe', 'digitalocean', 'cloudstorage', '...'],
  },
  {
    title: 'Aqua4Fish',
    logo: Aqua4FishLogo,
    url: 'https://www.aqua4fish.com',
    img: Aqua4FishImage,
    description: 'projectPortfolio.aqua4fish.description',
    languages: 'projectPortfolio.language.germanEnglish',
    hashtags: ['react', 'nodejs', 'cloud foundry', 'cloudant', 'sendgrid', '...'],
  },
  {
    title: 'SeifenSiede.',
    logo: SeifenSiedePunktLogo,
    url: 'https://www.seifensiedepunkt.de',
    img: SeifenSiedePunktImage,
    description: 'projectPortfolio.seifensiedepunkt.description',
    languages: 'projectPortfolio.language.german',
    hashtags: ['react', 'nodejs', 'heroku', 'mongodb', 'pdf exporter', '...'],
  },
];

function ProjectCard({ title, logo, description, img, url, languages, hashtags }: ProjectCardProps) {
  return (
    <div className="h-full p-4 w-full lg:w-1/3 ">
      <a href={url} target="_blank" className="mx-auto flex items-center">
        <div className="h-full divide-y divide-gray-400 mx-auto overflow-hiddenbg-white shadow-lg overflow-hidden m-4 rounded border border-2 border-grey-600">
          <div>
            <img
              className="w-full"
              src={img}
            />
            <div className="mx-auto mt-3 px-4 flex flex-wrap">
              <div className="mx-auto flex items-center">
                <img src={logo} className="h-10" />
                <span className="text-3xl ml-2">{title}</span>
              </div>
              <span className="w-full text-sm text-center text-gray-600">
                <FormattedMessage
                  id={languages}
                  defaultMessage="Language of project"
                  description="Language for project"
                />
              </span>
            </div>
            <div className="mt-2 p-4">
              <FormattedMessage
                id={description}
                defaultMessage="Description of project"
                description="Description for project"
              />
            </div>
          </div>
          <div className="w-full justify-center p-2 select-none flex flex-wrap">
            {
              hashtags.map(hashtag => <Pill key={hashtag} hashtag={hashtag}></Pill>)
            }
          </div>
        </div>
      </a>
    </div>
  );
}

export function ProjectPortfolio() {
  return (
    <section
      className="w-full container mx-auto p-6"
      id="workExperience"
    >
      <h2 className="text-3xl mb-2 font-bold text-center">
        <FormattedMessage
          id={"header.navigation.projectPortfolio"}
          defaultMessage="Selected Projects"
          description="Title for my projects"
        />
      </h2>
      <div className="flex flex-row flex-wrap">
        { 
          MyProjects.map((project, index) => <ProjectCard key={`project-${index}`} {...project}/>)
        }
      </div>
    </section>
  );
}
