import React from 'react';
import { FormattedMessage } from 'react-intl';

export function Imprint() {
  return (
    <>
      <div className="pt-8 bg-gray-900"></div>
      <div className="w-full container mx-auto my-6">
        <div className="text-3xl">
          <FormattedMessage
            id={"imprint.imprint.title"}
            defaultMessage="Impress"
            description="Impress title"
          />
        </div>
        <div className="grid">
          <span>Christian Bühlmeyer - Freier Entwickler</span>
          <span>Friedrichstrasse 36</span>
          <span>95448 Bayreuth</span>
          <span>
            <FormattedMessage
              id={"imprint.imprint.contact"}
              defaultMessage="Kontakt"
              description="Translation of 'Kontakt'"
            />
            :&nbsp;contact@christian-buehlmeyer.com
          </span>
        </div>
        <div>
          <span>
            <FormattedMessage
              id={"imprint.imprint.responsibilityText"}
              defaultMessage="Responsible for this website"
              description="Translation of Responsibility Text"
            />: &nbsp;
          </span>
          <span>Christian Bühlmeyer</span>
        </div>
      </div>
      <div className="w-full container mx-auto my-6">
        <div className="text-3xl">
          <FormattedMessage
            id={"imprint.liability.title"}
            defaultMessage="Liability notice"
            description="Translation of 'Liability notice'"
          />
        </div>
        <div className="grid">
          <p>
            <FormattedMessage
              id={"imprint.liability.text"}
              defaultMessage="Liability notice"
              description="Translation of 'Liability notice'"
            />
          </p>
        </div>
      </div>
    </>
  );
}
