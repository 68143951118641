import { createBrowserHistory } from 'history';
import * as ReactGA from 'react-ga';

const history = createBrowserHistory();

history.listen(location => historyListener(location));

ReactGA.initialize('UA-80165319-19');

const historyListener = (location: any) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname); 
}

historyListener(window.location);

export default history;