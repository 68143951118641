import { Languages } from "models/enums/Languages";

export function getLanguage(): Languages {
    const browserLanguageString = navigator.language.split(/[-_]/)[0];
    switch(browserLanguageString) {
        case 'en': return Languages.English;
        case 'de': return Languages.German;
        default: return Languages.English;
    }
}
