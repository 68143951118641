import React from 'react';
import { FormattedMessage } from 'react-intl';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import MeImg from '../../assets/images/christian.jpg';

export function AboutMe() {
  return (
    <section
      className="w-full"
      id="aboutMe"
    >
      <div className="container mx-auto p-6 pt-10">
        <Zoom>
          <img src={MeImg} className="h-64 rounded-full mx-auto"/>
        </Zoom>
        <h1 className="w-1/2 mx-auto text-2xl text-center mt-4">
          <FormattedMessage
            id={"aboutMe.description.text"}
            defaultMessage="My name is Christian, I am a full-stack developer and my aim is to help you build amazing solutions for your customers"
            description="Link to the contact"
          />
        </h1>
      </div>
    </section>
  );
}
