import React from 'react';

interface PillProps {
  hashtag: string;
};

export function Pill(props: PillProps) {
  const { hashtag } = props;

  return (
    <div className="my-2 py-2 px-4 shadow-md rounded-full font-sans font-semibold text-sm border border-grey-600 mr-2">
      {hashtag}
    </div>
  );
}
