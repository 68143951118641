import React from 'react';
import { FormattedMessage } from 'react-intl';
import Flip from 'react-reveal/Flip';

interface OfferCardProps {
  title: string;
  description: Array<string>;
  colorString: string;
}

const Offer: Array<OfferCardProps> = [
  {
    title: 'offering.webDevelopment.title',
    description: [
      'offering.webDevelopment.descriptionLine1',
      'offering.webDevelopment.descriptionLine2',
    ],
    colorString: 'purple',
  },
  {
    title: 'offering.seo.title',
    description: [
      'offering.seo.descriptionLine1',
      'offering.seo.descriptionLine2',
    ],
    colorString: 'pink',
  },
  {
    title: 'offering.designThinking.title',
    description: [
      'offering.designThinking.descriptionLine1',
      'offering.designThinking.descriptionLine2',
    ],
    colorString: 'teal',
  },
  {
    title: 'offering.consulting.title',
    description: [
      'offering.consulting.descriptionLine1',
      'offering.consulting.descriptionLine2',
    ],
    colorString: 'orange',
  },
]

function OfferCard({ title, description, colorString }: OfferCardProps) {
  return (
    <div className="p-2 w-full md:w-1/2 lg:w-1/4">
      <Flip left>
        <div className={`h-full bg-gray-100 rounded border border-2 border-grey-600 overflow-hidden shadow-lg`}>
          <div className="px-6 py-4">
            <div className={`font-bold text-xl text-${colorString}-600 mb-4`}>
              <FormattedMessage
                id={title}
                defaultMessage="Offer"
                description="Service that we offer (e.g. Web design)"
              />
            </div>
            <p className="text-gray-700 text-base">
              { 
                description.map((text, index) => (
                  <React.Fragment key={`offerDescription-${index}`}>
                    <span>
                      <FormattedMessage
                        id={text}
                        defaultMessage="Offer description"
                        description="Description of service that we offer (e.g. Web design)"
                      />
                    </span><br/><br/>
                  </React.Fragment>
                ))
              }
            </p>
          </div>
        </div>
      </Flip>
    </div>
  );
}

export function WhatWeDo() {
  return (
    <section
      className="w-full bg-gradient-to-r from-teal-400 to-blue-500 text-gray-200 py-6"
      id="whatIDo"
    >
      <div className="w-full container mx-auto p-6">
        <h2 className="text-3xl mb-4 font-bold text-center">
          <FormattedMessage
            id={"header.navigation.offering"}
            defaultMessage="What we offer"
            description="Link to the offering"
          />
        </h2>
        <div className="flex flex-row flex-wrap">
          { 
            Offer.map((offer, index) => <OfferCard key={`offering-${index}`} {...offer}/>)
          }
        </div>
      </div>
    </section>
  );
}
